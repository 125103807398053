import React, { memo, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaTimes } from "react-icons/fa";
import { Encrypt_Value, Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import ProviderList from "./ProviderList/index";
import HeadNav from "./HeadNav";
import { PageTitle, Text, ModalDiv } from "../../StyledComponents";
import { Button } from "./StyledComponents";
import { Div } from "./Styles";
import { ChatTabTitle, MessageContiner, Modal, ModalContainer } from "./Styles";
import { ModalHeader } from "../setting/PatientPortalSettings/StyledComponents";
import ChatIndex from "./chatpopupBody/chatIndex";

import {
  getSecureMessageList,
  getUnreadCount,
  setPreviewDownload,
} from "../../StateManagement/Reducers/MessageState";
import {
  DataDiv,
  Image,
  Tooltip,
  TooltipText,
} from "../top_navbar/Notifications/styles";
import DocType from "../../assets/images/docType.png";
import SendIcon from "../../assets/images/SendButton.png";
import Microphone_Start from "../../assets/images/Microphone_Start.png";
import Microphone_Pause from "../../assets/images/MicroPhone_Pause.png";
import Microphone_End from "../../assets/images/MicroPhone_End.png";
import audioPreviewButton from "../../assets/images/Circled Play.png";
import PatientChatLists from "./Patient Chat/patientChatLists";
import axiosInstance from "../../axios/axios";
import Chat from "./Patient Chat/chats";
import {
  Close,
  DowloadBtn,
  Row,
  SearchInputText,
  Attach,
} from "./Patient Chat/Message/styles";
import AudioPreview from "./Patient Chat/chats/audioPreview";
import { FiTrash2 } from "react-icons/fi";
import { BsInfoCircle } from "react-icons/bs";
import UpgradeRequired from "../popups/UpgradeRequired";
import webmFixDuration from "webm-duration-fix";

const Messenger = (props) => {
  // ------------------------------ State And Variables Start------------------------ //
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [sessioVal, setSessionVal] = useState(null);
  const [session, setSession] = useState("");
  const [headData, setHeadData] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const [imgPre, setImgPre] = useState(false);
  const [recId, setRecId] = useState([]);
  const [recId2, setRecId2] = useState("");
  const [common_msg, setCommon_msg] = useState("");
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [Shows, setShows] = useState(false);
  const [value1, setValue1] = useState("");

  const [msg, setMsg] = useState([]);
  const userList = useSelector((state) => state.Revision.ProviderList);
  const secureMsgList = useSelector((state) => state.Message.SecureMessageList);
  const NewMsgs = useSelector((state) => state.Message.NewMsgs);
  const count = useSelector((state) => state.Message.totalcount);
  const [MsgCount, setMsgCount] = useState(0);
  const [viewType, setViewType] = useState("");
  const [patientChatMsgs, setPatientchatMsgs] = useState({
    session: "",
    headData: "",
    loading: true,
    selectedFile: "",
    sessioVal: "",
    patPic: "",
    fileName: "",
    preview: "",
    imgPre: "",
    ImgUrl: "",
  });
  const [PreviewDownloads, setPreviewDownloads] = useState("");
  const userIds = userList.map((user) => user.id);
  const headData1 = {
    ProviderName: "General",
    date: null,
    id: null,
    img_url: null,
    message: null,
    type: null,
    unreadCount: "0",
  };
  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozo");
  const provname = localStorage.getItem("provider_name")
    ? Decrypt_Value(localStorage.getItem("provider_name"), "vozo")
    : "";
  const hiddenFileInput = React.useRef(null);
  const [showAlert, setShowAlert] = useState(false);
  const [Refresh, setRefresh] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [audioURLs, setAudioURLs] = useState([]);
  const audioContext = useRef(null);
  const audioContext1 = useRef(null);
  const mediaRecorder = useRef(null);
  const mediaRecorder1 = useRef(null);
  const audioChunks = useRef([]);

  const audioChunks1 = useRef([]);
  const audioStream = useRef(null);
  const audioStream1 = useRef(null);
  const [isRecordingPaused, setIsRecordingPaused] = useState(false);
  const [audioURLs1, setAudioURLs1] = useState([]);
  const [recordingKey, setRecordingKey] = useState(0);
  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(true);
  const [three, setThree] = useState(false);
  const [record, compRecord] = useState(false);
  const [delClick, setDelClick] = useState(false);
  const accountType = Decrypt_Value(
    localStorage.getItem("account_type"),
    "vozo"
  );
  const startRecording = async () => {
    try {
      compRecord(false);
      if (!audioStream.current) {
        audioStream.current = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
      }
      audioChunks.current = [];
      audioContext.current = new (window.AudioContext ||
        window.webkitAudioContext)();
      // const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(audioStream.current);
      setRecordingKey((prevKey) => prevKey + 1);
      mediaRecorder.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);
        }
      };
      const audioBlobs = [];
      mediaRecorder.current.onstop = async () => {
        const duration = Date.now();
        const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
        audioBlobs.push(audioBlob);
        const fixedBlob = await webmFixDuration(audioBlob, duration);
        setAudioURLs1(URL.createObjectURL(audioBlob));

        audioChunks.current = [];
        handleOnSend(fixedBlob);
      };

      mediaRecorder.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };
  const writeString = (view, offset, string) => {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };
  const createWavBuffer = (interleaved, numberOfChannels, sampleRate) => {
    const buffer = new ArrayBuffer(44 + interleaved.length * 2);
    const view = new DataView(buffer);

    writeString(view, 0, "RIFF");
    view.setUint32(4, 32 + interleaved.length * 2, true);
    writeString(view, 8, "WAVE");
    writeString(view, 12, "fmt ");
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true); // PCM format
    view.setUint16(22, numberOfChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * 2 * numberOfChannels, true);
    view.setUint16(32, numberOfChannels * 2, true);
    view.setUint16(34, 16, true); // 16-bit sample size
    writeString(view, 36, "data");
    view.setUint32(40, interleaved.length * 2, true);

    // write the PCM samples
    const offset = 44;
    for (let i = 0; i < interleaved.length; i++) {
      const sample = Math.max(-1, Math.min(1, interleaved[i]));
      view.setInt16(
        offset + i * 2,
        sample < 0 ? sample * 0x8000 : sample * 0x7fff,
        true
      );
    }

    return buffer;
  };

  const createWavBlob = async (audioBuffer) => {
    const numberOfChannels = audioBuffer.numberOfChannels;
    const sampleRate = audioBuffer.sampleRate;
    const length = audioBuffer.length;

    const interleaved = new Float32Array(length * numberOfChannels);
    for (let channel = 0; channel < numberOfChannels; channel++) {
      const channelData = audioBuffer.getChannelData(channel);
      for (let i = 0; i < length; i++) {
        interleaved[i * numberOfChannels + channel] = channelData[i];
      }
    }

    const wavBuffer = createWavBuffer(
      interleaved,
      numberOfChannels,
      sampleRate
    );

    return new Blob([wavBuffer], { type: "audio/wav" });
  };

  const processAudioBlob = async (blobdata) => {
    try {
      // Step 1: Decode audio data from Blob
      const arrayBuffer = await blobdata.arrayBuffer();
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const decodedData = await audioContext.decodeAudioData(arrayBuffer);
      const wavBlob = await createWavBlob(decodedData);
      // handleDownloadBlob(wavBlob);
      return wavBlob;
    } catch (error) {
      console.error("Error processing audio blob:", error);
    }
  };
  const startRecording1 = async () => {
    try {
      if (!audioStream1.current) {
        audioStream1.current = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
      }

      audioContext1.current = new (window.AudioContext ||
        window.webkitAudioContext)();
      // const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder1.current = new MediaRecorder(audioStream1.current);

      mediaRecorder1.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks1.current.push(event.data);
        }
      };

      mediaRecorder1.current.onstop = () => {
        const audioBlob = new Blob(audioChunks1.current, { type: "audio/wav" });

        const audioURL = URL.createObjectURL(audioBlob);
        setAudioURLs((prevAudioURLs) => [...prevAudioURLs, audioURL]);

        audioChunks1.current = [];
      };

      mediaRecorder1.current.start();

      setThree(false);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const pauseRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.pause();
      setTwo(true);
      setIsRecording(false);
      setIsRecordingPaused(true);
    }
  };

  const pauseRecording1 = () => {
    if (
      mediaRecorder1.current &&
      mediaRecorder1.current.state === "recording"
    ) {
      mediaRecorder1.current.pause();
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "paused") {
      mediaRecorder.current.resume();
      setIsRecording(true);
      setIsRecordingPaused(false);
    }
  };

  const resumeRecording1 = () => {
    if (mediaRecorder1.current && mediaRecorder1.current.state === "paused") {
      mediaRecorder1.current.resume();
    }
  };

  const stopRecording1 = () => {
    setThree(true);
    setOne(true);
    compRecord(true);
    if (
      mediaRecorder1.current &&
      (mediaRecorder1.current.state === "recording" ||
        mediaRecorder1.current.state === "paused")
    ) {
      mediaRecorder1.current.stop();
      audioStream1.current.getTracks().forEach((track) => {
        track.stop();
      });
      audioStream1.current = null;
      audioContext1.current.close();
    }
  };

  const stopRecording = () => {
    compRecord(true);
    if (three && audioURLs.length === 0) {
      return;
    }

    if (delClick) {
      audioStream.current = null;
    }
    if (
      mediaRecorder.current &&
      (mediaRecorder.current.state === "recording" ||
        mediaRecorder.current.state === "paused")
    ) {
      mediaRecorder.current.stop();
      audioStream.current !== null &&
        audioStream.current.getTracks().forEach((track) => {
          track.stop();
        });
      audioStream.current = null;
      audioContext.current.close();
      setIsRecording(false);
      setIsRecordingPaused(false);
    }
    setTimeout(() => {
      // setAudioURLs([]);
      setOne(false);
    }, 1500);
  };

  function getAudioBlobDuration(audioBlob) {
    return new Promise((resolve, reject) => {
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const fileReader = new FileReader();
      fileReader.onload = function (event) {
        const arrayBuffer = event.target.result;
        audioContext.decodeAudioData(arrayBuffer, function (buffer) {
          const duration = Math.floor(buffer.duration);
          resolve(duration);
        });
      };
      if (audioBlob instanceof Blob) {
        fileReader.readAsArrayBuffer(audioBlob);
      } else {
        reject("Not a Blob");
      }
    });
  }

  const handleOnSend = async (audioBlob) => {
    try {
      let duration1 = null;

      if (audioBlob !== undefined && audioBlob.size > 0) {
        duration1 = await getAudioBlobDuration(audioBlob);
        console.log("Audio duration:", duration1);
      }

      if (!record) {
        if (three && audioURLs.length === 0) {
          return;
        }

        if (delClick) {
          audioStream.current = null;
        }
        if (
          mediaRecorder.current &&
          (mediaRecorder.current.state === "recording" ||
            mediaRecorder.current.state === "paused")
        ) {
          mediaRecorder.current.stop();
          audioStream.current !== null &&
            audioStream.current.getTracks().forEach((track) => {
              track.stop();
            });
          audioStream.current = null;
          audioContext.current.close();
          setIsRecording(false);
          setIsRecordingPaused(false);
        }
      }

      let article = {
        message: value1,
        ipAddress: "127.0.0.1",
        sender_id: user_id,
        sender: "Provider",
        recip_id: props.providerName,
        receive_id: patientChatMsgs.sessioVal.toString(),
        status: 1,
        message_type: "text",
        timerVal: duration1,
      };

      let enc = Encrypt_Value(article, "vozo_portal");

      const filedata = new FormData();
      filedata.append("message", enc);
      let audioBlobModified = await processAudioBlob(audioBlob);
      if (audioBlobModified !== undefined && audioBlobModified.size > 0) {
        filedata.append("audio", audioBlobModified, "recorded_audio.wav");
      } else if (patientChatMsgs.selectedFile) {
        filedata.append("fileupload", patientChatMsgs.selectedFile);
      } else if (value1.trim() === "") {
        return false;
      }

      axiosInstance
        .post("/vozo/patient/sendMessage", filedata)
        .then((res) => {
          setRefresh(!Refresh);
          // getPatientMsgs();
        })
        .catch((e) => console.log(e));

      setValue1("");
      setPatientchatMsgs({ ...patientChatMsgs, selectedFile: null });
      setAudioURLs([]);
      setAudioURLs1([]);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  // ------------------------------State And Variables End------------------------ //

  // ------------------------------Functions Start------------------------------- //

  const selFileNull = () => {
    setPatientchatMsgs({ ...patientChatMsgs, selectedFile: null });
  };
  const imgPreFls = () => {
    setPatientchatMsgs({ ...patientChatMsgs, imgPre: false });
  };
  const valSetfunc = (e) => setValue1(e.target.value);
  const toDelete = () => {
    if (window.confirm("Are you sure you want to delete?")) {
      setAudioURLs([]);
      setAudioURLs1([]);
      setOne(false);
      setTwo(true);
      setIsRecording(false);
      setIsRecordingPaused(false);
      setDelClick(true);
    }
  };
  const toggleImg = () => {
    setTwo(false);
  };

  const handlePreview = (item) => {
    let getName = item.split("/");
    setPatientchatMsgs({
      ...patientChatMsgs,
      imgPre: true,
      img_url: item,
      fileName: getName[getName.length - 1],
    });
  };
  const OnChangeSearch = (temp) => {
    setSearch(temp);
  };

  const handleClick = (val, item) => {
    if (item === "fromgeneral") {
      setLoading(true);
      setHeadData(headData1);
      setRecId(userIds);
      setRecId2("");
      setSession("General");
      setSessionVal(-1);
      setImgPre(false);
      setSelectedFile(null);
      const data = { sender_id: user_id, receive_id: item.id, gen: 1 };
      dispatch(getUnreadCount(data));
      setCommon_msg(1);
      setValue("");
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } else {
      localStorage.setItem("Prov_Id", Encrypt_Value(item.id, "vozo"));
      setLoading(true);
      setHeadData(item);
      setSession(item.ProviderName);
      setSessionVal(item.id);
      dispatch(setPreviewDownload(""));
      setRecId([item.id]);
      setRecId2(item.id);
      setImgPre(false);
      setSelectedFile(null);
      setCommon_msg(0);
      setValue("");
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleSecureMsgClick = (val, item) => {
    setValue1("");
    setIsRecording(false);
    setIsRecordingPaused(false);
    if (patientChatMsgs.sessioVal === item.pid) {
      return;
    }
    setPatientchatMsgs({ ...patientChatMsgs, loading: true });

    localStorage.setItem("Prov_Id", Encrypt_Value(item.id, "vozo"));
    let a = {
      img_url: item.img_url,
      ProviderName: item.PatName,
    };
    let obj = {
      session: item.PatName,
      patPic: item.img_url,
      headData: a,
      loading: true,
      selectedFile: "",
      sessioVal: item.pid,
    };
    setPatientchatMsgs(obj);

    dispatch(setPreviewDownload(""));
  };

  const handleClose = () => {
    setSession("");
    setSessionVal(null);
    setImgPre(false);
    setSelectedFile(null);
    setCommon_msg("");
    setHeadData(null);
    setRecId([]);
    setRecId2("");
    setMsg([]);
    setPatientchatMsgs({
      session: "",
      headData: "",
      loading: true,
      selectedFile: "",
      sessioVal: "",
      patPic: "",
      fileName: "",
      preview: "",
      imgPre: "",
      ImgUrl: "",
    });
    setShows(false);
    props.close();
  };

  const handleContainerClick = (e) => {
    e.stopPropagation();
  };

  const handleopenPatChat = (x) => {
    setPatientchatMsgs({ ...patientChatMsgs, sessioVal: "" });
    setSessionVal(null);
    setSearch("");
    if (x === "true") {
      setShows(true);
    } else {
      setShows(false);
    }
  };
  const getPatientMsgs = () => {
    dispatch(getSecureMessageList({ userId: user_id }));
  };

  const handleChat = (val) => {
    if (val.length !== 0) {
      setPatientchatMsgs({
        ...patientChatMsgs,
        session: val.PatName,
        sessioVal: val.pid,
        patPic: val.img_url,
      });
    }
    // getPatientMsgs();
    // refreshMsgs();
  };

  const handleDownload = () => {
    let data = {
      file: patientChatMsgs.img_url,
    };
    let enc = Encrypt_Value(data, "vozoportal");
    axiosInstance
      .put("/vozo/PreviewDownloadmessenger", enc)
      .then((res) => {
        let Data = Decrypt_Value(res.data, "vozoportal");
        setPreviewDownloads(Data);
      })
      .catch((e) => {
        console.log(e);
        setPreviewDownloads("");
      });
  };
  const filtInputAttach = () => hiddenFileInput.current.click();

  const handleChange = (event) => {
    const ext = event.target.files[0].type.split("/")[1];
    switch (ext) {
      case "jpg":
      case "jpeg":
      case "bmp":
      case "png":
      case "pdf":
        setPatientchatMsgs({
          ...patientChatMsgs,
          selectedFile: event.target.files[0],
        });
        break;
      default:
        setShowAlert(true);
        // setSelectedFile();
        setPatientchatMsgs({ ...patientChatMsgs, selectedFile: undefined });
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
    }
  };

  // ------------------------------Functions End-------------------------- //
  // ------------------------------UseEffect Start-------------------------- //

  useEffect(() => {
    getPatientMsgs();
  }, []);

  useEffect(() => {
    if (patientChatMsgs.sessioVal !== "") {
      let body = {
        Provider_Id: user_id,
        Pid: patientChatMsgs.sessioVal,
      };
      let enc = Encrypt_Value(body, "vozo_portal");
      axiosInstance
        .post("/vozo/patient/getMessagelist", enc)
        .then((res) => {
          let DecodeVal = Decrypt_Value(res.data, "vozo_portal");
          setMsg(DecodeVal);

          setPatientchatMsgs({ ...patientChatMsgs, loading: false });

          let readchats = [];
          readchats = DecodeVal.filter((item) => item.type !== "date");

          let article = {
            Provider_Id: user_id,
            Pid: patientChatMsgs.sessioVal,
            chats: readchats,
            Sender: "Patient",
          };
          let enc = Encrypt_Value(article, "vozo");
          axiosInstance
            .put("/vozo/patient/setreadmsgs", enc)
            .then((res) => {
              getPatientMsgs();
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [patientChatMsgs.sessioVal, Refresh]);

  useEffect(() => {
    if (!patientChatMsgs.selectedFile) {
      setPatientchatMsgs({ ...patientChatMsgs, previe: undefined });
      return;
    }

    var checkType = patientChatMsgs.selectedFile.type.split("/");

    if (checkType[0] === "image") {
      setViewType("image");
    } else {
      setViewType("doc");
    }

    const objectUrl = URL.createObjectURL(patientChatMsgs.selectedFile);
    setPatientchatMsgs({
      ...patientChatMsgs,

      fileName: patientChatMsgs.selectedFile.name,
      preview: objectUrl,
    });
    return () => URL.revokeObjectURL(objectUrl);
  }, [patientChatMsgs.selectedFile]);

  useEffect(() => {
    if (PreviewDownloads !== "") {
      var tag = document.createElement("a");
      tag.href = PreviewDownloads.file;
      tag.download = PreviewDownloads.filename;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    }
  }, [PreviewDownloads]);

  useEffect(() => {
    setMsgCount(NewMsgs);
  }, [NewMsgs]);

  // ------------------------------UseEffect End-------------------------- //
  return (
    <>
      <Modal
        show={props.show2}
        justifyContent="center"
        onClick={handleClose}
        width="100%"
        height="100%"
      >
        <ModalContainer
          position="relative"
          justifyContent="center"
          borderRadius="8px"
          width="970px"
          height="600px"
          onClick={handleContainerClick}
        >
          <ModalHeader padding="13px 11px 8px 20px">
            <ChatTabTitle Active={Shows}>
              <PageTitle
                fontFamily="'Open Sans',sans-serif"
                fontWeight="600"
                fontSize="18px"
                lineHight="normal"
                color="rgba(46, 46, 46, 0.50)"
                padding="0px"
                cursor="pointer"
                margin="0px 0px 0px 0px"
                onClick={() => handleopenPatChat("false")}
                className={Shows ? "" : "ref-manage-preview-active"}
                position="relative"
              >
                Practice chat
                {count > 0 && (
                  <DataDiv
                    position="absolute"
                    left="calc(100% + 10px)"
                    minWidth="16px"
                    justifyContent="center"
                    display="flex"
                    aspectRatio="1/1"
                    MaxWidth="24px"
                    background="#2C7BE5"
                    br="50px"
                    mt="0px"
                  >
                    <Text
                      textAlign="center"
                      fontFamily="Inter"
                      fontWeight="500"
                      fontSize="12px"
                      color="#ffffff"
                      lineHeight="15px"
                      height="100%"
                      display="flex"
                    >
                      {count}
                    </Text>
                  </DataDiv>
                )}
              </PageTitle>

              <PageTitle
                position="relative"
                fontFamily="'Open Sans',sans-serif"
                fontWeight="600"
                fontSize="18px"
                lineHight="normal"
                color="rgba(46, 46, 46, 0.50)"
                cursor="pointer"
                padding="0px"
                margin="0px 0px 0px 35px"
                onClick={() => handleopenPatChat("true")}
                className={Shows ? "ref-manage-preview-active" : ""}
              >
                Client Chat
                {MsgCount !== 0 && (
                  <DataDiv
                    position="absolute"
                    left="calc(100% + 10px)"
                    minWidth="16px"
                    justifyContent="center"
                    display="flex"
                    aspectRatio="1/1"
                    MaxWidth="24px"
                    background="#2C7BE5"
                    br="50px"
                    mt="0px"
                  >
                    <Text
                      textAlign="center"
                      fontFamily="Inter"
                      fontWeight="500"
                      fontSize="12px"
                      color="#ffffff"
                      lineHeight="15px"
                      height="100%"
                      display="flex"
                    >
                      {MsgCount}
                    </Text>
                  </DataDiv>
                )}
              </PageTitle>
            </ChatTabTitle>
            <>
              <Button
                border="none"
                marginLeft="auto"
                background="white"
                cursor="pointer"
                onClick={handleClose}
              >
                <FaTimes
                  onClick={handleClose}
                  style={{ height: "18px", width: "18px", color: "#2E2E2E" }}
                />
              </Button>
            </>
          </ModalHeader>

          {Shows ? (
            <MessageContiner
              position="relative"
              justifyContent="center"
              padding="16px 16px 15px 0px"
              height="551px"
            >
              <PatientChatLists
                list={secureMsgList}
                sessioVal={patientChatMsgs.sessioVal}
                getMsgs={getPatientMsgs}
                handleClick={handleSecureMsgClick}
              />
              {patientChatMsgs.sessioVal ||
              parseInt(patientChatMsgs.sessioVal) === 0 ? (
                <Div
                  width="63%"
                  height="100%"
                  background="#FFF"
                  position="relative"
                  border="1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="8px"
                  overflow="hidden"
                >
                  <HeadNav data={patientChatMsgs.headData} />
                  <Chat
                    handleChat={handleChat}
                    data={msg}
                    patName={patientChatMsgs.session}
                    patpic={patientChatMsgs.patPic}
                    handlePreview={handlePreview}
                    selectedFile={patientChatMsgs.selectedFile}
                    setSelectedFile={(file) =>
                      setPatientchatMsgs({
                        ...patientChatMsgs,
                        selectedFile: file,
                      })
                    }
                    setViewType={setViewType}
                    profileimage={props.profileimage}
                    providerName={props.providerName}
                    loading={patientChatMsgs.loading}
                  />
                  {patientChatMsgs.selectedFile && (
                    <Row
                      position="absolute"
                      top="11%"
                      width="100%"
                      height="77%"
                      backgroundColor="rgba(244, 244, 244, 0.9)"
                      display="flex"
                      flexDirection="column"
                      padding="20px"
                      radius="4px"
                    >
                      <Row
                        dis="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Text fontSize="13px" lineHeight="18px" color="#91929e">
                          {patientChatMsgs.fileName}
                        </Text>
                        <Close onClick={selFileNull} />
                      </Row>
                      {viewType === "image" && (
                        <Row
                          position="relative"
                          dis="flex"
                          flexDirection="row"
                          justifyContent="center"
                          alignItems="center"
                          height="100%"
                          width="100%"
                          overFlow="hidden"
                        >
                          <Image
                            src={patientChatMsgs.preview}
                            width="max-content"
                            height="max-content"
                            MaxHeight="220px"
                            MaxWidth="220px"
                            margin="auto"
                            objectFit="contain"
                          />
                        </Row>
                      )}
                      {viewType === "doc" && (
                        <Row
                          position="relative"
                          dis="flex"
                          flexDirection="row"
                          justifyContent="center"
                          alignItems="center"
                          height="100%"
                          width="100%"
                          overFlow="hidden"
                        >
                          <Image
                            src={DocType}
                            width="max-content"
                            height="max-content"
                            MaxHeight="220px"
                            MaxWidth="220px"
                            margin="auto"
                          />
                        </Row>
                      )}
                    </Row>
                  )}

                  {patientChatMsgs.imgPre && (
                    <Row
                      position="absolute"
                      top="11%"
                      width="100%"
                      height="89.5%"
                      backgroundColor="rgba(244, 244, 244, 0.9)"
                      display="flex"
                      flexDirection="column"
                      padding="20px"
                      borderRadius="4px"
                      overFlow="hidden"
                    >
                      <Row
                        dis="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        marginBottom="10px"
                      >
                        <Row dis="flex">
                          <Text
                            fontSize="13px"
                            lineHeight="18px"
                            color="#91929e"
                            marginBottom="5px"
                          >
                            {patientChatMsgs.fileName}
                          </Text>
                          <DowloadBtn onClick={handleDownload} />
                        </Row>
                        <Row dis="flex">
                          <Close onClick={imgPreFls} />
                        </Row>
                      </Row>
                      {viewType === "image" && (
                        <Row
                          position="relative"
                          dis="flex"
                          flexDirection="row"
                          justifyContent="center"
                          alignItems="center"
                          height="100%"
                          width="100%"
                          overFlow="hidden"
                        >
                          <Image
                            src={patientChatMsgs.img_url}
                            objectFit="contain"
                            width="max-content"
                            height="max-content"
                            MaxHeight="220px"
                            MaxWidth="220px"
                          />
                        </Row>
                      )}
                      {viewType === "doc" && (
                        <iframe
                          title="img"
                          src={patientChatMsgs.img_url}
                          width="100%"
                          height="100%"
                          frameBorder="0"
                        />
                      )}
                    </Row>
                  )}
                  {!patientChatMsgs.imgPre && (
                    <Row
                      dis="flex"
                      position="relative"
                      margin="0px 10px 16px 20px"
                      background="#ffffff"
                      border="1px solid #EAEAEA"
                      boxShadow="0px 1px 2px rgba(184, 200, 224, 0.222055)"
                      radius="8px"
                      marginLeft="20px"
                      display="flex"
                      maxHeight="fit-content"
                      padding="8px 12px 8px 17px"
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                      // style={{
                      //   textAlign: "center",
                      // }}
                    >
                      <SearchInputText
                        type="file"
                        accept=".pdf,.png,.jpg,.bmp,.jpeg"
                        ref={hiddenFileInput}
                        onChange={(e) => handleChange(e)}
                        className="d-none"
                      />

                      {!isRecording && !isRecordingPaused ? (
                        <>
                          <Attach onClick={filtInputAttach} />

                          <SearchInputText
                            placeholder="Type your message here …"
                            value={value1}
                            paddingBottom="2px"
                            width="100%"
                            onChange={(e) => valSetfunc(e)}
                            onKeyPress={(e) =>
                              e.key === "Enter" && handleOnSend()
                            }
                          />
                        </>
                      ) : (
                        <Div marginRight="20px">
                          <RecordingStatus
                            isRecording={isRecording}
                            isRecordingPaused={isRecordingPaused}
                            audioURLs1={audioURLs1}
                            audioURLs={audioURLs}
                            recordingKey={recordingKey}
                            one={one}
                          />
                        </Div>
                      )}
                      <Div display="flex" alignItems="center">
                        {!isRecording && !isRecordingPaused ? (
                          <Image
                            mr="20px"
                            width="16px"
                            height="20px"
                            color="#fdc748"
                            cursor="pointer"
                            src={Microphone_Start}
                            alt="mike"
                            onClick={() => {
                              startRecording1();
                              startRecording();
                            }}
                          />
                        ) : isRecording && !isRecordingPaused ? (
                          <Div display="flex" alignItems="center">
                            {" "}
                            <Image
                              mr="20px"
                              width="20px"
                              height="20px"
                              color="#fdc748"
                              cursor="pointer"
                              src={Microphone_Pause}
                              alt="mike"
                              onClick={() => {
                                pauseRecording();
                                pauseRecording1();
                              }}
                            />
                            <Image
                              mr="5px"
                              width="22px"
                              height="22px"
                              color="#fdc748"
                              cursor="pointer"
                              src={SendIcon}
                              onClick={handleOnSend}
                            />
                          </Div>
                        ) : (
                          !isRecording &&
                          isRecordingPaused && (
                            <Div display="flex" alignItems="center">
                              {!one && (
                                <Tooltip>
                                  <Image
                                    mr="12px"
                                    width="16px"
                                    height="20px"
                                    color="#fdc748"
                                    cursor="pointer"
                                    src={Microphone_End}
                                    alt="mike"
                                    onClick={() => {
                                      resumeRecording();
                                      resumeRecording1();
                                    }}
                                  />
                                  <TooltipText className="TooltipText">
                                    Resume
                                  </TooltipText>
                                </Tooltip>
                              )}
                              <Div
                                // onClick={toggleImg}
                                display="flex"
                                alignItems="center"
                              >
                                {!isRecording && isRecordingPaused && two ? (
                                  <Tooltip>
                                    <Image
                                      width="24px"
                                      height="24px"
                                      color="#fdc748"
                                      cursor="pointer"
                                      src={audioPreviewButton}
                                      onClick={() => {
                                        stopRecording1();
                                        toggleImg();
                                      }}
                                    />
                                    <TooltipText className="TooltipText">
                                      Preview
                                    </TooltipText>
                                  </Tooltip>
                                ) : (
                                  !isRecording &&
                                  isRecordingPaused &&
                                  !two && (
                                    <FiTrash2
                                      onClick={toDelete}
                                      style={{
                                        cursor: "pointer",
                                        color: "red",
                                        height: "22px",
                                        width: "22px",

                                        marginRight: "10px",
                                      }}
                                    />
                                  )
                                )}

                                <Tooltip>
                                  <Image
                                    mr="5px"
                                    width="24px"
                                    height="24px"
                                    color="#fdc748"
                                    cursor="pointer"
                                    src={SendIcon}
                                    onClick={stopRecording}
                                    mt="1px"
                                    ml="10px"
                                  />
                                  <TooltipText className="TooltipText">
                                    send
                                  </TooltipText>
                                </Tooltip>
                              </Div>
                            </Div>
                          )
                        )}
                      </Div>
                      {!isRecording && !isRecordingPaused && (
                        <Image
                          mr=" 5px"
                          ml=" auto"
                          width="24px"
                          height="24px"
                          color="#fdc748"
                          cursor="pointer"
                          src={SendIcon}
                          onClick={handleOnSend}
                        />
                      )}
                    </Row>
                  )}
                </Div>
              ) : (
                <Div
                  width="63%"
                  background="#FFF"
                  position="relative"
                  height="100%"
                  FlexCenter
                  border="1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="5px"
                  // style={{
                  //   border: "1px solid rgba(46, 46, 46, 0.25)",
                  //   borderRadius: "5px",
                  // }}
                >
                  <Text
                    fontFamily="'Open Sans',sans-serif"
                    fontWeight="400"
                    fontSize="16px"
                    lineHeight="16px"
                    color="#000"
                    textAlign="start"
                    padding="14px 0px 0px 19px"
                  >
                    Please select a chat
                  </Text>
                </Div>
              )}
            </MessageContiner>
          ) : (
            <MessageContiner
              position="relative"
              justifyContent="center"
              padding="16px 16px 15px 0px"
              height="551px"
              //  style={{ height: "551px" }}
            >
              <ProviderList
                list={userList}
                sessioVal={sessioVal}
                search={search}
                OnChangeSearch={OnChangeSearch}
                handleClick={handleClick}
              />
              {sessioVal || sessioVal === 0 ? (
                <Div
                  width="63%"
                  height="100%"
                  background="#FFF"
                  position="relative"
                  border="1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="8px"
                  overflow="hidden"
                >
                  <HeadNav data={headData} />
                  <ChatIndex
                    userId={user_id}
                    ProviderName={provname}
                    receive_id={recId}
                    receive_id2={recId2}
                    userData={headData}
                    selectedFile={selectedFile}
                    imgPre={imgPre}
                    setImgPre={setImgPre}
                    setSelectedFile={setSelectedFile}
                    common_msg={common_msg}
                    loading={loading}
                    value={value}
                    setValue={setValue}
                    list={userList}
                    session={session}
                  />
                </Div>
              ) : (
                <Div
                  width="63%"
                  background="#FFF"
                  position="relative"
                  height="100%"
                  FlexCenter
                  border="1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="5px"
                  // style={{
                  //   borderRadius: "5px",
                  // }}
                >
                  <Text
                    fontFamily="'Open Sans',sans-serif"
                    fontWeight="400"
                    fontSize="16px"
                    lineHeight="16px"
                    color="#000"
                    textAlign="start"
                    padding="14px 0px 0px 19px"
                  >
                    Please select a chat
                  </Text>
                </Div>
              )}
            </MessageContiner>
          )}
        </ModalContainer>
      </Modal>
      <Modal show={showAlert} className="alert-popup-message">
        <ModalDiv className="alert msg alert-danger" role="alert">
          <BsInfoCircle className="icons-bs" />
          File Not allowed
        </ModalDiv>
      </Modal>
    </>
  );
};

const RecordingStatus = ({
  isRecording,
  isRecordingPaused,
  audioURLs,
  recordingKey,
  one,
}) => {
  const [timer, setTimer] = useState(0);
  const [getUrls, SetUrls] = useState([]);
  useEffect(() => {
    let interval;

    if (isRecording && !isRecordingPaused) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    } else if (!isRecordingPaused) {
      clearInterval(interval);
      setTimer(0);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isRecording, isRecordingPaused]);

  useEffect(() => {
    SetUrls(audioURLs);
  }, [audioURLs]);

  return (
    <Div>
      {isRecording ? (
        <Div display="flex" alignItems="center">
          <Div>{timer}s</Div>
          <Div marginLeft="12px" className="voice-note-animation"></Div>
        </Div>
      ) : (
        !one && (
          <Div display="flex" alignItems="center">
            <Div>{timer}s</Div>
            <Div
              marginLeft="20px"
              className="voice-note-animation paused-animation"
            ></Div>
          </Div>
        )
      )}
      {isRecordingPaused && (
        <Div
          display="flex"
          alignItems="center"
          textAlign="center"
          justifyContent="center"
          paddingBottom="3px"
        >
          <Div marginLeft="6px">
            {getUrls && <AudioPreview key={recordingKey} audioBlob={getUrls} />}
          </Div>
        </Div>
      )}
    </Div>
  );
};
export default memo(Messenger);
