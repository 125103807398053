import React from "react";
import { SubNavItem, ListItem, Div, SubMenu } from "./styles";
const BillingNav = () => {
  return (
    <Div width="100%" paddingLeft="0.86em">
      <SubMenu textAlign="left" width="100%">
        <ListItem padding="8px" margin="8px 0 0 0">
          <SubNavItem to="/billing/insuranceclaim">Insurance Claims</SubNavItem>
        </ListItem>
        <ListItem padding="8px" margin="0">
          <SubNavItem to="/billing/patientpayment" exact>
            Client Payments
          </SubNavItem>
        </ListItem>
        <ListItem padding="8px" margin="0">
          <SubNavItem to="/billing/patientstatement" exact>
            Client Statements
          </SubNavItem>
        </ListItem>
        <ListItem padding="8px" margin="0">
          <SubNavItem to="/billing/patientbalance" exact>
            Client Ledger
          </SubNavItem>
        </ListItem>
        <ListItem padding="8px" margin="0">
          <SubNavItem to="/billing/feeschedule" exact>
            Fee Schedule
          </SubNavItem>
        </ListItem>
        <ListItem padding="8px" margin="0">
          <SubNavItem to="/billing/eob" exact>
            EOB
          </SubNavItem>
        </ListItem>
        <ListItem padding="8px" margin="0">
          <SubNavItem to="/billing/cardtransactions" exact>
            Card Transactions
          </SubNavItem>
        </ListItem>
        <ListItem padding="8px" margin="0">
          <SubNavItem to="/billing/era" exact>
            ERA
          </SubNavItem>
        </ListItem>
      </SubMenu>
    </Div>
  );
};

export default BillingNav;
